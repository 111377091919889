import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div>Latter Day Leaders Website</div>
    <div>Coming Soon</div>
    <a href='https://app.latterdayleaders.org'>Go to App</a>
  </React.StrictMode>
);
